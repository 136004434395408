.section-usefull {
  $page_images_dir: $images_dir + 'usefull/';

  padding: 25px 0 40px;
  text-align: center;

  ul {
    li {
      padding-left: 24px;
      @include background-image-retina($page_images_dir + 'bullet', 'png', 10px, 15px);
      background-position: left 3px;
    }
  }

  .row-hidden{
    display: none;
  }

  .more {
    font-size: 30px;
    margin-top: 35px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      @include background-image-retina($page_images_dir + 'arrow', 'png', 31px, 16px);
      display: block;
      width: 31px;
      height: 16px;
      right: -45px;
      top: 17px;
    }


    @media (max-width: 500px) {
      font-size: 20px;
      &:after{
        top: 8px;
      }
    }
  }
}
.section-offices {
  $page_images_dir: $images_dir + 'offices/';

  padding: 70px 0 100px;

  h2 {
    margin-bottom: 50px;
  }

  li {
    a{
      padding-left: 52px;
      background-position: left center;
      background-repeat: no-repeat;
      line-height: 34px;
      display: inline-block;
    }
    &.flag-1 a{
      @include background-image-retina($page_images_dir + '01', 'png', 40px, 34px);
    }
    &.flag-2 a{
      @include background-image-retina($page_images_dir + '02', 'png', 40px, 34px);
    }
    &.flag-3 a{
      @include background-image-retina($page_images_dir + '03', 'png', 34px, 34px);
    }
    &.flag-4 a{
      @include background-image-retina($page_images_dir + '04', 'png', 34px, 34px);
    }
    &.flag-5 a{
      @include background-image-retina($page_images_dir + '05', 'png', 34px, 34px);
    }
    &.flag-6 a{
      @include background-image-retina($page_images_dir + '06', 'png', 34px, 34px);
    }
    &.flag-7 a{
      @include background-image-retina($page_images_dir + '07', 'png', 34px, 34px);
    }
    &.flag-8 a{
      @include background-image-retina($page_images_dir + '08', 'png', 34px, 34px);
    }
    &.flag-9 a{
      @include background-image-retina($page_images_dir + '09', 'png', 34px, 34px);
    }
    &.flag-10 a{
      @include background-image-retina($page_images_dir + '10', 'png', 34px, 34px);
    }
    &.flag-11 a{
      @include background-image-retina($page_images_dir + '11', 'png', 40px, 34px);
    }
    &.flag-12 a{
      @include background-image-retina($page_images_dir + '12', 'png', 40px, 34px);
    }
    &.flag-13 a{
      @include background-image-retina($page_images_dir + '13', 'png', 40px, 34px);
    }
  }
}
@mixin scada {
  font-family: 'Scada', sans-serif;
  font-weight: 400;
}

@mixin italic{
  font-style: italic;
}

@mixin bold {
  @include scada;
  font-weight: 700;
}

@mixin opensans {
  font-family: 'Open Sans', sans-serif;
}

@mixin semibold{
  @include opensans;
  font-weight: 600;
}

.section-services {
  padding: 33px 0 45px;

  .btn-big {
    width: 380px;
    display: block;
    margin: 40px auto 0;
    max-width: 97%;
  }

  .service-item {
    text-align: center;
    margin-bottom: 20px;
    h3 {
      display: block;
      margin-top: 10px;
      line-height: 30px;
      font-size: 30px;
      margin-bottom: 15px;
      small {
        font-size: 18px;
        color: #737373;
        display: block;
      }
    }
  }
}
body {
  @include scada;
  color: #000;
}

p{
  line-height: 25px!important;
}

.table-def{
  border: 1px solid #000!important;
}

.table-def tbody tr {
  background-color: transparent!important;
}

.table-def tbody tr td{
  padding: 6px!important;
  border: 1px solid #000!important
}

.box-table-def .span-def{
    font-size: 14px;
    text-align: center;
    display: block;
    margin-top: 10px;
    transform: translate(-1%);
}

.list-body{
  margin-top: 15px;
}

.list-body > li{
  line-height: 25px;
  margin-left: 2.5em;
}

.list-faq{
  margin-top: 10px;
  margin-bottom: 10px;
}

.list-faq > li{
  line-height: 25px;
  margin-left: 1.0em;
  background-image: none!important;
}

.faq-align{
  text-align: left!important;
}

.btn-action-popup{
      display: none;
    }

a {
  text-decoration: none;
}

ul > li > a {
  border: none!important;
  outline: none!important;
}

a:focus, a:hover {
  text-decoration: none;
}

b, strong {
  font-weight: normal;
  @include bold;
}

table {
  margin: 0 auto;
}

tr:nth-child(odd) {
background-color: #efefef;
}

.btn {
  @include border-radius(4px);
  border: 2px solid $color_red;
  @include border-radius(5px);
  text-align: center;
  color: #000;
  padding: 0;
  font-size: 20px;
  line-height: 42px;
  &:hover {
    color: #fff;
    background: $color_red;
  }

  &.btn-big {
    line-height: 84px;
    height: 84px;
    font-size: 30px;

    @media (max-width: 450px) {
      font-size: 25px;
      height: 70px;
      line-height: 70px;
    }
    @media (max-width: 380px) {
      font-size: 20px;
      height: 50px;
      line-height: 50px;
    }
  }

  &.btn-red {
    @include bold;
    font-size: 20px;
    background: $color_red;
    color: #fff;
  }
}

h2, .h2 {
  display: block;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  font-size: 30px;
  line-height: 34px;

  &.border-bottom {
    background: url($images_dir + 'subheader_dashed.png') repeat-x left bottom;
    padding-bottom: 10px;
  }

  span {
    position: relative;
    display: inline-block;

    &:before, &:after {
      content: '';
      height: 1px;
      top: 16px;
      background: url($images_dir + 'subheader_dashed.png') repeat-x;
      position: absolute;
      display: block;
    }

    &:before {
      left: -10000px;
      right: 103%;
    }
    &:after {
      right: -10000px;
      left: 103%;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: #195eb3;
}

h1 {
  font-size: 30px;
}

i {
  @include italic;
}

address {
  font-style: normal;
}

section {
  &.dark {
    background: url($images_dir + 'bg_dark.jpg');
  }
}

a.more {
  @include semibold;
  color: $color_blue;
  font-size: 18px;
  line-height: 100%;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.bullet-list {
  margin: 13px 0;
  li {
    padding-left: 14px;
    text-align: left;
    margin-left: 34px;
    font-size: 16px;
    line-height: 19px;
    @include background-image-retina($images_dir + 'bullet', 'png', 6px, 7px);
    background-position: left 5px;
  }
}

.numeric-list {
  margin: 13px 0;
  li {
    padding: 4px 0 0 40px;
    text-align: left;
    margin-left: 34px;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    margin-bottom: 5px;

    &:before {
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      background: $color_blue;
      @include border-radius(50px);
      line-height: 25px;
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      text-align: center;
    }

    &:nth-child(1):before {
      content: '1';
    }
    &:nth-child(2):before {
      content: '2';
    }
    &:nth-child(3):before {
      content: '3';
    }
    &:nth-child(4):before {
      content: '4';
    }
    &:nth-child(5):before {
      content: '5';
    }
    &:nth-child(6):before {
      content: '6';
    }
    &:nth-child(7):before {
      content: '7';
    }
    &:nth-child(8):before {
      content: '8';
    }
    &:nth-child(9):before {
      content: '9';
    }
  }
}

.links-list {

  text-align: left;
  // margin-left: 75px;
  li {
    font-size: 24px;
    display: block;
    margin-bottom: 10px;
    min-height: 0px;
    line-height: 100%;

    a {
      color: #000;
      text-decoration: none;
      font-size: 17px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 500px) {
    margin-left: 10px;
    padding-left: 0px;
    .question{
      font-size: 17px;
    }
  }
}


/* #btn_request {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
  -khtml-opacity: 0.2;
  position: fixed;
  left: -96px;
  height: 60px;
  line-height: 50px;
  top: 500px;
  width: 230px;
  background: $color_red;
  display: block;
  color: #fff;
  font-size: 25px;
  @include bold;
  white-space: nowrap;
  @include rotate(-90);
  text-align: center;
  &:hover {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -khtml-opacity: 1;
  }
  @media(max-width: 600px) {
    display: none;
  }

  @media(max-height: 730px) {
    top: 250px;
  }
  @media(max-height: 450px) {
    top: 150px;
  }
  @media(max-height: 300px) {
    display: none;
  }
} */

.border-top {
  background: url($images_dir + 'subheader_dashed.png') repeat-x;
  padding-top: 70px;

  @media (max-width: 767px) {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .cell-fix-height {
    height: auto !important;
  }
}

.price {
  @include bold;
  color: #e43026;
}

.read-more {
  text-align: center;
  overflow: hidden;

  .text-container {
    padding-top: 70px;
    font-size: 20px;
    > span {
      margin-bottom: 15px;
      display: block;
    }
  }

  ul {
    display: inline-block;
    li {
      padding: 20px 32px 17px;
      background: url($images_dir + 'subheader_dashed.png') repeat-x bottom;
      &:last-child {
        background: none;
      }
    }
  }

  img {
    max-width: 100%;
  }
}

p {
  &.info, &.red, &.blue {
    height: auto !important;
    text-align: left;
    margin-left: 0;
    padding-left: 36px;
    background-position: left top;
    background-repeat: no-repeat;
    padding-right: 15px;
    min-height: 20px;
    padding-top: 2px;
    &.blue {
      @include background-image-retina($images_dir + 'blue', 'png', 20px, 20px);
    }

    &.red {
      @include background-image-retina($images_dir + 'red', 'png', 20px, 20px);
    }

    &.info {
      @include background-image-retina($images_dir + 'info', 'png', 20px, 20px);
    }
  }
  img {
    display: inline !important;
    margin: 0 !important;
  }
}

.consulprice-list {
  .disclaimer {
    font-size: 12px;
    margin: 20px;
    line-height: 14px;
    color: #555555;
  }
  li {
    margin-bottom: 10px;
    background: #f5f5f5;
    padding: 12px 0 12px 42px;
    @include border-radius(5px);
    @media (max-width: 400px) {
      padding-left: 12px;
    }

    .name {
      font-size: 18px;
      display: inline-block;
      margin-right: 10px;
    }
    .categories {
      color: #444444;
      font-size: 14px;
      display: inline-block;
    }

    .left {
      margin-right: 150px;
      @media (max-width: 600px) {
        margin-right: 90px;
      }
    }

    .age {
      font-size: 20px;
      display: inline-block;
      padding-left: 30px;
      line-height: 20px;
      margin-left: 240px;
      @include background-image-retina($images_dir + 'age','png', 21px, 20px);
      @media (max-width: 1000px) {
        margin-left: 20px;
      }
      @media (max-width: 760px) {
        margin-left: 0;
        display: block;
        margin-top: 5px;
      }
      @media (max-width: 400px) {
        font-size: 14px;
      }
    }

    .price {
      float: right;
      width: 145px;
      font-size: 20px;
      display: inline-block;

      @media (max-width: 600px) {
        width: 90px;
      }
      .currency {
        color: #000;
        @include scada;
        font-size: 18px;
      }
    }
  }
}

article.full-text{
  margin-top: 0;
  h1,h2,h3,h4,h5,h6{
    display: block;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Montserrat', sans-serif;
    color: #195eb3;
  }

  p{
    text-align: justify;
  }


  img{
    margin: 20px auto !important;
    display: block !important;
    max-width: 100%;
  }

  ul,li {
    list-style: inside;
  }
  .plagiat-img img{
    max-height: 500px;
  }
  .mail-image {
    display: inline-block;
  }
    .mail-image img{
    margin: 0 !important;
    width: auto;
    display: inline-block;
    position: relative;
    top: 6px;
  }
  .phone-image {
    display: inline-block;
  }
  #phone-image_1 img{
    margin: 0 !important;
    width: auto;
    display: inline !important;
    position: relative;
    background-position: left center;
  }
    #phone-image_2 img{
    margin: 0 !important;
    width: auto;
    display: inline !important;
    position: relative;
    background-position: left center;
    padding-left: 5px;
  }
    #phone-image_3 img{
    margin: 0 !important;
    width: auto;
    display: inline !important;
    position: relative;
    background-position: left center;
    padding-left: 5px;
  }

}



article.review {
  >p{
    &:after{
      content: '';
      display: block;
      height: 60px;
      width: 100%;
      background-image: url($images_dir + 'hr.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

article.color_style{
  margin-top: 0;
          }
.contacts {
  text-align: center;
}
.images {
  width: 100%;
  float: left;
}
.red-text {
  color: red;
  font-weight: bold;
}
.without-icon {
  list-style: none !important;
  background-image: none !important;
}
.breadcrumb_line {
  position: relative;
  width: 100%;
  min-height: 30px;
  margin-top: 20px;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;

li {
  vertical-align: top;
  position: relative;
  margin-right: 10px;
  float: left;

  }

  a, span {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 767px) {
    .hidden-xs {
      display: none;
  }
  .video-none{
    display: none!important;
  }
}

.section-about {
  padding: 45px 0 25px;
  h2 {
    margin-bottom: 35px;
  }

  .container {
    height: 100%;
    width: 100%;
    max-width: 1200px;
  }

  .item {
    background: #f5f5f5;
    padding: 35px 10px;
    @include border-radius(5px);
    text-align: center;
    max-width: 375px;
    margin: 0 auto 20px;
    h3 {
      text-align: left;
      font-size: 29px;
      line-height: 100%;
      display: block;
      margin-bottom: 20px;
      margin-left: 10px;
      @media (max-width: 1200px) {
        font-size: 27px;
      }
      @media (max-width: 1110px) {
        font-size: 25px;
      }
      @media (max-width: 1040px) {
        font-size: 23px;
      }

      @media (max-width: 960px) {
        font-size: 21px;
      }
      @media (max-width: 900px) {
        font-size: 19px;
      }
      @media (max-width: 830px) {
        height: 57px;
      }
      @media (max-width: 780px) {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        font-size: 29px;
        height: auto;
      }
    }
    p {
      text-align: left;
      height: 275px;
      display: block;
      margin-left: 10px;
      margin-right: 30px;
      img {
        margin: 30px -40px 30px -10px;
        max-width: 100%;
      }
      font-size: 16px;
      line-height: 100%;
      color: #727272;

      @media (max-width: 1130px) {
        height: 290px;
        margin-right: 0;
        img {
          margin: 30px auto;
        }
      }

      @media (max-width: 1005px) {
        height: 320px;
        font-size: 15px;
      }

      @media (max-width: 930px) {
        font-size: 14px;
      }


      @media (max-width: 767px) {
        font-size: 16px;
        height: auto;
        margin-bottom: 20px;
      }

    }
    .more {
      display: inline-block;
    }

  }
}
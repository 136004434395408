body > footer {
  $page_images_dir: $images_dir + 'footer/';

  height: 83px;
  line-height: 14px;
  padding-top: 0px;
  color: #727272;
  font-size: 12px;
  position: relative;

  .copyright {
    float: left;
    width: 50%;
    padding-right: 50px;
    padding-top: 0px;
    font-size: 11px;

  }

  .member {
    padding: 0 0px;
    float: right;
    width: 50%;
    text-align: right;
    img {
      margin-left: 5px;
    }
  }

  @media (max-width: 655px) {
    .copyright{
      padding-top: 10px;
    }
    .member{
      text-align: center;
      padding-right: 10px;
    }
  }


  @media (max-width: 400px) {
    .copyright, .member{
      display: none;
    }
  }

  .btn-up {
    position: absolute;
    width: 98px;
    left: 50%;
    margin-left: -49px;
    top: 0;
    bottom: 0;
    line-height: 83px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    display: block;
    background: $color_blue;
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -50px;
      height: 50px;
      width: 98px;
      display: block;
      @include background-image-retina($page_images_dir + 'arrow', 'png', 98px, 50px);
    }
  }
}

body > header {
  $page_images_dir: $images_dir + 'header/';
  position: relative;
  background: url($page_images_dir + 'visa-london.jpg') bottom no-repeat;
  background-size: cover;
  .row{
    margin: 0;
  }
  nav > ul > .with-submenu > a{
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
    }
  nav > ul > .nav-item-7 >a:hover{
    opacity: 0.8;
  }  
  .social-buttons-mobile{
    display: none;
   } 
  .mail-link > a{
    color: #fff;
    font-size: 25px;
    white-space: nowrap;
    margin-top: 0px;
  }
  .container {
    height: 100%;
    width: 97% !important;
    max-width: 1560px;
  }
  .slogan-mobile{
    display: none;
  }

  > .container {
    width: 100%;
  }
  .bg-container {
    position: absolute;
    left: 50%;
    margin-left: -540px;
    width: 1080px;
    top: 0;
    bottom: 0;
    z-index: 0;
    display: none;
  }

  .logo {
    position: absolute;
    left: 75%;
    top: 25px;
    width: 300px;
    height: 100px;
    @include background-image-retina($page_images_dir + 'logo', 'png', 272px, 89px);
    margin-top: 50px;
    z-index: 500;
  }
  .row-menu{
    width: 50%;
    margin-top: 50px;
  }
  nav {
    width: 100%;
    > ul {
      height: 200px;
      width: 50%;
      float: left;
      transition: opacity 1s;

      &:first-child {
        padding-right: 0px;
      }
      &:last-child {
        /* padding-left: 34px; */
      }

      > li {
        float: left;
        height: 50%;
        width: 50%;
        > a {
          color: #fff;
          padding-top: 40px;
          @include bold;
          font-size: 20px;
          line-height: 24px;
          display: block;
          height: 100%;
          &:hover, &:focus {
            color: #fff;
            text-decoration: none;
          }
        }

        &.nav-item-1 {
          > a {
            padding-left: 14px;
            @include background-image-retina($page_images_dir + 'nav_01', 'png', 39px, 30px);
            background-position: 14px 24px;
            padding-top: 70px;
          }
        }
        &.nav-item-2 {
          > a {
            padding-left: 14px;
            @include background-image-retina($page_images_dir + 'nav_02', 'png', 32px, 30px);
            background-position: 14px 24px;
            padding-top: 70px;
          }
          .submenu ul {
            padding-left: 5px;
          }
        }
        &.nav-item-3 {
          > a {
            padding-left: 14px;
            @include background-image-retina($page_images_dir + 'nav_03', 'png', 33px, 32px);
            background-position: 14px 24px;
            padding-top: 70px;
          }
        }
        &.nav-item-4 {
          > a {
            padding-left: 14px;
            @include background-image-retina($page_images_dir + 'nav_04', 'png', 27px, 32px);
            background-position: 14px 24px;
            padding-top: 70px;
          }
        }
        &.nav-item-5 {
          > a {
            padding-left: 14px;
            @include background-image-retina($page_images_dir + 'nav_05', 'png', 32px, 32px);
            background-position: 14px 44px;
            padding-top: 90px;
        }
      }
      &.nav-item-6 {
        > a {
          padding-left: 14px;
          @include background-image-retina($page_images_dir + 'nav_06', 'png', 32px, 32px);
          background-position: 14px 44px;
          padding-top: 90px;
        }
      }
      &.nav-item-7 {
        > a {
          padding-left: 14px;
          @include background-image-retina($page_images_dir + 'nav_07', 'png', 32px, 32px);
          background-position: 14px 44px;
          padding-top: 90px;
        }
      }
      &.nav-item-8 {
        > a {
          padding-left: 14px;
          padding-top: 90px;
          @include background-image-retina($page_images_dir + 'nav_08', 'png', 40px, 27px);
          background-position: 14px 44px;
        }
      }

        &:hover {
          /* background: #03a8f8; */
          &.nav-item-1, &.nav-item-2, &.nav-item-3, &.nav-item-4 {
            .submenu {
              @media (min-width: 769px) {
                display: block !important;
              }
            }

            > a {
              position: relative;
              &:after {
                content: '';
                border: 7px solid transparent;
                height: 0px;
                width: 0px;
                float: left;
                line-height: 0;
                font-size: 0;
                bottom: -13px;
                left: 50%;
                margin-left: -7px;
                border-top-color: #fff;
                position: absolute;
                z-index: 111;
              }
            }
          }
        }
      &:hover {
          /* background: #03a8f8; */

          /*&.nav-item-5, &.nav-item-6, &.nav-item-7, &.nav-item-8 {*/
          &.nav-item-5, &.nav-item-6, &.nav-item-8 {  
            .submenu {
              @media (min-width: 769px) {
                display: block !important;
              }
            }

            > a {
              position: relative;
              &:after {
                content: '';
                border: 7px solid transparent;
                height: 0px;
                width: 0px;
                float: left;
                line-height: 0;
                font-size: 0;
                bottom: -27px;
                left: 50%;
                margin-left: -7px;
                border-top-color: #fff;
                position: absolute;
                z-index: 111;
              }
            }
          }
        }  
      }
    }
.nav-item-6, .nav-item-7, .nav-item-8, .nav-item-5 {
  .submenu {
    top: 215px !important;
  }
}
    .submenu {
      padding: 10px 0;
      padding-bottom: 5px;
      background: rgba(32, 32, 32, 1);
      position: absolute;
      left: 0;
      right: 0;
      top: 100px;
      display: none;
      overflow: hidden;
      z-index: 2;
      margin-top: 48px;


      ul {
        text-align: left;
        padding-left: 10px;
        li {
          font-size: 13px;
          font-family: Montserrat;
          font-weight: 400;
          line-height: 16px;
          margin-bottom: 8px;
          padding-left: 15px;
          @include background-image-retina($page_images_dir + 'bullet', 'png', 6px, 7px);
          a {
            color: #fff;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .subheader {
        margin: 25px 0;
        display: block;
        text-align: center;
        position: relative;
        span {
          font-size: 20px;
          color: #fff;
          display: inline-block;
          position: relative;

          &:before, &:after {
            height: 2px;
            background: #fff;
            content: '';
            top: 14px;
            position: absolute;
            display: block;
          }

          &:before {
            right: 150px;
            left: -10000px;
          }
          &:after {
            left: 150px;
            right: -10000px;
          }
        }
      }
    }
  }

  .social-buttons {
    display: block;
    text-align: left;
    font-size: 0;
    margin-top: 30px;
    a {
      display: inline-block;
      margin-right: 10px;
      width: 35px;
      height: 35px;

      &.inst {
        @include background-image-retina($page_images_dir + 'instagram-link', 'png', 32px, 32px);
      }
      &.youtube-btn {
        @include background-image-retina($page_images_dir + 'youtube', 'png', 32px, 32px);
      }
      &.facebook-btn {
        @include background-image-retina($page_images_dir + 'facebook', 'png', 32px, 32px);
      }
      &.btn-find{
        @include background-image-retina($page_images_dir + 'button', 'png', 198px, 51px);
      }
    }
  }

  .block-text{
    color: #fff;
    width: 50%;
    font-weight: 300;
    margin-top: 40px;

  }
  .contact-item{
    margin-bottom: 20px;
  }

  .text-container {
    margin: 0 auto 0 auto;
    margin-top: 30px;
    float: left;
    font-family: 'Montserrat', sans-serif;
    @include clearfix;

    @media(max-width: 960px) {
      margin-top: 0;
    }

    .heading-wrapper {

      .slogan {
         span:first-child {
          font-size: 90px;
          line-height: 101%;
          display: block;
          white-space: nowrap;
          color: #fff;
          font-weight: 600;
        }
        span:last-child {
          font-size: 72px;
          line-height: 100%;
          margin-top: 5px;
          color: #fff;
          display: block;
          padding-bottom: 10px;
          white-space: nowrap;
          margin-top: 125px;
          margin-bottom: 30px;
          font-weight: 400;
        }
      }

      > span {
        line-height: 40px;
        font-size: 33px;
        display: block;
        margin-top: 8px;
        letter-spacing: 0.01em;
        white-space: nowrap;
      }
    }

  }
  .contacts-container {
      text-align: left;
      padding-left: 0!important;
      padding-right: 0!important;
      margin-top: 40px; 

      .mail-link {
        font-size: 25px;
        margin-top: 0px;
        display: inline-block;
        @include background-image-retina($page_images_dir + 'mail', 'png', 25px, 18px);
        background-position: left center;
        padding-left: 48px;
      }
      .small {
        img {
          margin-bottom: 5px;
        }
      }

      .btn-find {
        width: 200px;
        height: 51px;
        display: inline-block;
        margin-right: 60px;
        background-position: left;
      }
    }

  .contacts-item{
    margin: 0 auto 0 auto;
    margin-top: 30px;
    text-align: right;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    @include clearfix;

    @media(max-width: 960px) {
      margin-top: 0;
    }
      .phone-link_1 {
        font-size: 25px;
        white-space: nowrap;
        color: #fff;
        @include background-image-retina($page_images_dir + 'phone-link', 'png', 25px, 18px);
        background-position: left center;
        padding-left: 35px;
      }
      .phone-link_2 {
        font-size: 25px;
        white-space: nowrap;
        color: #fff;
        @include background-image-retina($page_images_dir + 'phone-link', 'png', 25px, 18px);
        background-position: left center;
        padding-left: 35px;
      }
      .phone-link_3 {
        font-size: 25px;
        white-space: nowrap;
        color: #fff;
        @include background-image-retina($page_images_dir + 'phone-link', 'png', 25px, 18px);
        background-position: left center;
        padding-left: 35px;
      }
      .phone-link_1 > a{
        font-size: 25px;
        white-space: nowrap;
        color: #fff;
      }
      .phone-link_2 > a{
        font-size: 25px;
        white-space: nowrap;
        color: #fff;
      }
      .phone-link_3 > a{
        font-size: 25px;
        white-space: nowrap;
        color: #fff;
      }
      .mail-link {
        font-size: 25px;
        margin-top: 0px;
        color: #fff;
        display: inline-block;
        @include background-image-retina($page_images_dir + 'mail', 'png', 25px, 18px);
        background-position: left center;
        padding-left: 48px;
      }
    }

@media (min-width: 576px) and (max-width: 768px) {
    height: auto !important;
    background: url($page_images_dir + 'visa-london.jpg') no-repeat;
    background-size: cover;
    background-position: center;

    .slogan-mobile{
    display: block;
    color: #fff;
    font-weight: 400;
    font-size: 40px;
    margin-bottom: 15px;
    margin-top: 50px;
    }
    .logo{
    position: absolute;
    left: 65%;
    top: 25px;
    width: 200px;
    height: 100px;
    @include background-image-retina($page_images_dir + 'logo-large', 'png', 180px, 59px);
  }
  .phone-link_1, .phone-link_2, .phone-link_3, .mail-link{
    font-size: 20px!important;
  }
  .phone-link_1 > a, .phone-link_2 > a, .phone-link_3 > a, .mail-link > a {
  font-size: 20px!important;
  }
  #mail-link {
    display: inline-block;
    @include background-image-retina($page_images_dir + 'mail-middle', 'png', 20px, 15px);
    background-position: left center;
    padding-left: 32px;
    padding-right: 15px;
  }
  .btn-find {
    margin-right: 15px;
  }  
  .slogan{
    display: none;
  }
  .social-buttons{
    display: none !important;
   } 
   .contacts-container{
    margin-top: 20px;
   }
   .text-container {
    margin-bottom: 50px;
    }
   .contacts-item{
    margin-top: 20px;
   }
   .btn-find {
    width: 150px!important;
    height: 55px!important;
    display: inline-block;
    margin-right: 60px;
    background: url($page_images_dir + 'button-middle.png') right bottom no-repeat !important;
    background-position: left;
   }
   .social-buttons-mobile{
    display: block;
    text-align: left;
    font-size: 0;
    margin-top: 0px;
    a {
      display: inline-block;
      margin-right: 5px;
      width: 35px;
      height: 35px;
      margin-bottom: 4px;
      
      &.inst {
        @include background-image-retina($page_images_dir + 'instagram-link', 'png', 32px, 32px);
        margin-right: 15px;
      }
      &.youtube-btn {
        @include background-image-retina($page_images_dir + 'youtube', 'png', 32px, 32px);
        margin-right: 15px;
      }
      &.facebook-btn {
        @include background-image-retina($page_images_dir + 'facebook', 'png', 32px, 32px);
        margin-right: 15px;
      }
      &.btn-find{
        @include background-image-retina($page_images_dir + 'button-middle', 'png', 150px, 39px);
      }
    }
  }
  .row-menu{
    width: 100%;
  }
  .row:first-child {
      padding-top: 80px;
    }
    .mobile-toggle {
      position: absolute;
      left: 16px;
      top: 78px;
      @include border-radius(0px);
      height: 40px;
      width: 40px;
      outline: none;

      i, &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 4px;
        background: #fff;
        @include border-radius(0px);
      }

      &:before {
        top: 0;
      }
      i {
        top: 12px;
      }
      &:after {
        top: 24px;
      }
    }

    nav {
      display: none;
      margin-top: 10px;
      > ul {
        float: none;
        width: 100%;
        height: auto;
        padding: 0 !important;
        > li {
          float: none;
          width: 100%;
          border-top: 1px solid #ffffff54;
          &:hover {
            background: transparent;
            a:after {
              display: none;
            }
          }
          > a {
            padding-left: 120px !important;
            background-position: 60px center !important;
            padding-top: 18px !important;
            height: 60px !important;
          }

          &.with-submenu {
            position: relative;
            &:before {
              content: '';
              width: 25px;
              height: 25px;
              display: block;
              background: url($page_images_dir + 'mobile_arrow_down.png');
              left: 10px;
              top: 18px;
              position: absolute;

              @media (max-width: 350px) {
                left: 15px;
              }
            }

            &.opened {
              &:before {
                background-image: url($page_images_dir + 'mobile_arrow_up.png');
              }
            }
          }
        }
      }

      .submenu {
        display: none;
        position: static;
        margin-top: 0px!important;
        overflow: inherit;

        .row {
          padding-top: 0;

          > div {
            width: 100% !important;
            ul {
              padding-left: 0px !important;
            }
          }
        }
      }
    }
  }
     @media (min-width: 481px) and (max-width: 575px) {
    .logo {
    left: 67%!important;
      }
    }
    @media (min-width: 421px) and (max-width: 480px) {
    .logo {
    left: 63%!important;
      }
    }
    
      @media (min-width: 375px) and (max-width: 420px) {
    .logo {
    left: 59%!important;
      }
    } 
  @media (min-width: 320px) and (max-width: 575px) {
    background-image: none;
    min-height: 100px !important;
    height: auto !important;
    background: url($page_images_dir + 'visa-london.jpg') no-repeat;
    background-size: cover;
    background-position: center;

    .contact-item {
      font-size: 0.7em;
    }
    .block-text{
      width: 100%;
    }
    .slogan-mobile{
    display: block;
    color: #fff;
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 15px;
    }
    .btn-find {
    display: inline-block;
    margin-right: 60px;
    background-position: left;
      } 
    .social-buttons-mobile{
    display: block;
    text-align: left;
    font-size: 0;
    margin-top: 0px;
    a {
      display: inline-block;
      margin-right: 3px;
      width: 35px;
      height: 35px;
      margin-bottom: 4px;
      
      &.inst {
        @include background-image-retina($page_images_dir + 'instagram-link-mobile', 'png', 28px, 28px);
      }
      &.youtube-btn {
        @include background-image-retina($page_images_dir + 'youtube-mobile', 'png', 28px, 28px);
      }
      &.facebook-btn {
        @include background-image-retina($page_images_dir + 'facebook-mobile', 'png', 28px, 28px);
      }
      &.btn-find{
       @include background-image-retina($page_images_dir + 'button-mobile', 'png', 110px, 28px);
     }
    }
  }
   .social-buttons{
    display: none !important;
   } 
   .contacts-container{
    margin-top: 20px;
   }
   .contacts-item{
    margin-top: 20px;
   }
    .slogan{
      display: none;
    }
    #mail-link {
    display: inline-block;
    @include background-image-retina($page_images_dir + 'mail-mobile', 'png', 20px, 14px);
    background-position: left center;
    padding-left: 32px;
    padding-right: 14px;

  }
  .contacts-item{
    text-align: left;
    .phone-link_1 > a, .phone-link_2 > a, .phone-link_3 > a, .mail-link > a {
      font-size: 14px;

    }
    .phone-link_1 > a, .phone-link_2 > a, .phone-link_3 > a{
      margin-bottom: 5px;
      display: inline-block;
    }
  
  .phone-link_1 {
    @include background-image-retina($page_images_dir + 'phone-link-mobile', 'png', 18px, 18px);
    background-position: left center;
    padding-left: 20px;
    }
  .phone-link_2 {
    @include background-image-retina($page_images_dir + 'phone-link-mobile', 'png', 18px, 18px);
    background-position: left center;
    padding-left: 20px!important;
    }
  .phone-link_3 {
    @include background-image-retina($page_images_dir + 'phone-link-mobile', 'png', 18px, 18px);
    background-position: left center;
    padding-left: 20px!important;
    }
  }


#mail-link > a{
  font-size: 14px;
  white-space: nowrap;
  color: #fff;
  }

  .logo {
    position: absolute;
    left: 58%;
    top: 0px;
    width: 120px;
    height: 39px;
    @include background-image-retina($page_images_dir + 'logo-mobile', 'png', 120px, 39px);
  }

    .text-container {
    width: 100%;
    margin: 0/*185px*/ auto 0 auto;
    float: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    margin-bottom: 50px;
    }
    .heading-wrapper {
      .slogan {
        span:first-child {
          font-size: 20px!important;
          background-size: auto 65px;
        }
      }
      > span {
        font-size: 20px;
      }
    }
    .heading-wrapper, .social-buttons {
      display: block;
    }
    .left-text {
      display: none;
    }

    .container{
      background: transparent !important;
    }

    .row:first-child {
      padding-top: 110px;
    }
    .row-menu{
    width: 100%;
    }
    .mobile-toggle {
      position: absolute;
      left: 16px;
      top: 55px;
      @include border-radius(0px);
      height: 30px;
      width: 40px;
      outline: none;

      i, &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 4px;
        background: #fff;
        @include border-radius(0px);
      }

      &:before {
        top: 0;
      }
      i {
        top: 12px;
      }
      &:after {
        top: 24px;
      }
    }

    nav {
      display: none;
      margin-top: 10px;
      > ul {
        float: none;
        width: 100%;
        height: auto;
        padding: 0 !important;
        > li {
          float: none;
          width: 100%;
          border-top: 1px solid #ffffff54;
          &:hover {
            background: transparent;
            a:after {
              display: none;
            }
          }
          > a {
            padding-left: 120px !important;
            background-position: 60px center !important;
            padding-top: 18px !important;
            height: 60px !important;
          }

          &.with-submenu {
            position: relative;
            &:before {
              content: '';
              width: 25px;
              height: 25px;
              display: block;
              background: url($page_images_dir + 'mobile_arrow_down.png');
              left: 10px;
              top: 18px;
              position: absolute;

              @media (max-width: 350px) {
                left: 15px;
              }
            }

            &.opened {
              &:before {
                background-image: url($page_images_dir + 'mobile_arrow_up.png');
              }
            }
          }
        }
      }

      .submenu {
        display: none;
        position: static;
        margin-top: 0px!important;

        .row {
          padding-top: 0;

          > div {
            width: 100% !important;
            ul {
              padding-left: 0px !important;
            }
          }
        }
      }
    }
  }

@media (min-width: 320px) and (max-width: 406px) {
  nav ul .nav-item-3 a{
    padding-top: 10px !important;
  }

}

@media (min-width: 320px) and (max-width: 363px) {
  nav ul .nav-item-2 a{
    padding-top: 0px !important;
  }
  nav > ul > .with-submenu > a{
    line-height: 20px
  }
}

@media (min-width: 363px) and (max-width: 470px) {
  nav ul .nav-item-2 a{
    padding-top: 10px !important;
  }
  nav > ul > .with-submenu > a{
    line-height: 20px
  }
}

@media (min-width: 320px) and (max-width: 435px) {
  nav ul .nav-item-1 a{
    padding-top: 10px !important;
  }
}


  &.header-big {
    /* min-height: 768px; */
        @media (min-width: 993px) and (max-width: 1200px) {
      .heading-wrapper {
      .slogan {
        span:last-child {
          font-size: 60px;
          line-height: 100%;
          margin-top: 5px;
          color: #fff;
          display: block;
          padding-bottom: 10px;
          white-space: nowrap;
          margin-top: 125px;
          margin-bottom: 30px;
        }
      }
    }
    .row-menu{
    width: 65%;
    margin-top: 50px;
  }
    nav > ul > .with-submenu > a{
      font-size: 15px;
    }
    .logo {
    position: absolute;
    left: 79%;
    top: 25px;
    width: 200px;
    height: 100px;
    @include background-image-retina($page_images_dir + 'logo-large', 'png', 180px, 59px);
  }
  }
  @media (min-width: 800px) and (max-width: 992px) {
      .heading-wrapper {
      .slogan {
        span:last-child {
          font-size: 50px;
          line-height: 100%;
          margin-top: 5px;
          color: #fff;
          display: block;
          padding-bottom: 10px;
          white-space: nowrap;
          margin-top: 125px;
          margin-bottom: 30px;
        }
      }
    }
    .row-menu{
    width: 75%;
    margin-top: 50px;
  }
    nav > ul > .with-submenu > a{
      font-size: 14px;
    }
    .btn-find {
    margin-right: 20px;
  }
  .logo {
    position: absolute;
    left: 76%;
    top: 25px;
    width: 180px;
    height: 100px;
    @include background-image-retina($page_images_dir + 'logo-large', 'png', 180px, 59px);
  }
  }
  @media (min-width: 769px) and (max-width: 799px) {
      .heading-wrapper {
        .slogan {
          span:last-child {
            font-size: 45px;
            line-height: 100%;
            margin-top: 5px;
            color: #fff;
            display: block;
            padding-bottom: 10px;
            white-space: nowrap;
            margin-top: 125px;
            margin-bottom: 30px;
          }
        }
      }
      .logo{
      position: absolute;
      left: 74%;
      top: 25px;
      width: 200px;
      height: 100px;
      @include background-image-retina($page_images_dir + 'logo-large', 'png', 180px, 59px);
    }
    .row-menu{
    width: 75%;
    margin-top: 50px;
  }
    nav > ul > .with-submenu > a{
        font-size: 13px;
      }
    .btn-find {
      margin-right: 19px;
    }

    }
  @media (min-width: 660px) and (max-width: 710px) {
      .logo {
      left: 68%!important;
    }
  }
  @media (min-width: 711px) and (max-width: 768px) {
      .logo {
      left: 72%!important;
    }
  }

    .text-container {
      @media(max-height: 900px) {
        margin-top: 0; /*100px;*/
      }

      @media(max-height: 830px) {
        margin-top: 0; /*100px;*/
      }

      @media(max-height: 760px) {
        .contacts-container, .contacts-item {
          margin-top: 10px; /*100px;*/
          }
        .slogan span:last-child{
          margin-top: 40px;
          margin-bottom: 5px;
        }
      }

      @media(max-height: 730px) {
        .contacts-container {
          .btn-find {
            /* display:none; */
          /*  margin-top: 20px;*/
          }
        }
      }
      @media(max-height: 900px) {
        .contacts-container {
          .btn-find {
            /* display:none; */
          /*  margin-top: 20px;*/
          }
        }
      }
    }
  }

  &.header-small {
    /* min-height: 768px; */
    .container{
      .left-text{
        display: none;
      }
    }
        @media (min-width: 993px) and (max-width: 1200px) {
      .heading-wrapper {
      .slogan {
        span:last-child {
          font-size: 60px;
          line-height: 100%;
          margin-top: 5px;
          color: #fff;
          display: block;
          padding-bottom: 10px;
          white-space: nowrap;
          margin-top: 125px;
          margin-bottom: 30px;
        }
      }
    }
    nav > ul > .with-submenu > a{
      font-size: 15px;
    }
    .logo {
    position: absolute;
    left: 79%;
    top: 25px;
    width: 200px;
    height: 100px;
    @include background-image-retina($page_images_dir + 'logo-large', 'png', 68px, 100px);
  }
  }
  @media (min-width: 800px) and (max-width: 992px) {
      .heading-wrapper {
      .slogan {
        span:last-child {
          font-size: 50px;
          line-height: 100%;
          margin-top: 5px;
          color: #fff;
          display: block;
          padding-bottom: 10px;
          white-space: nowrap;
          margin-top: 125px;
          margin-bottom: 30px;
        }
      }
    }
    nav > ul > .with-submenu > a{
      font-size: 15px;
    }
    .btn-find {
    margin-right: 20px;
  }
  .logo {
    position: absolute;
    left: 76%;
    top: 25px;
    width: 180px;
    height: 100px;
    @include background-image-retina($page_images_dir + 'logo-large', 'png', 68px, 100px);
  }
  }
  @media (min-width: 769px) and (max-width: 799px) {
      .heading-wrapper {
        .slogan {
          span:last-child {
            font-size: 50px;
            line-height: 100%;
            margin-top: 5px;
            color: #fff;
            display: block;
            padding-bottom: 10px;
            white-space: nowrap;
            margin-top: 125px;
            margin-bottom: 30px;
          }
        }
      }
      .logo{
      position: absolute;
      left: 74%;
      top: 25px;
      width: 200px;
      height: 100px;
      @include background-image-retina($page_images_dir + 'logo-large', 'png', 68px, 100px);
    }
    nav > ul > .with-submenu > a{
        font-size: 15px;
      }
    .btn-find {
      margin-right: 19px;
    }

    }
  @media (min-width: 660px) and (max-width: 710px) {
      .logo {
      left: 68%!important;
    }
  }
  @media (min-width: 711px) and (max-width: 768px) {
      .logo {
      left: 72%!important;
    }
  }

    .text-container {
      @media(max-height: 900px) {
        margin-top: 0; /*100px;*/
      }

      @media(max-height: 830px) {
        margin-top: 0; /*100px;*/
      }

      @media(max-height: 760px) {
        .contacts-container, .contacts-item {
          margin-top: 10px; /*100px;*/
          }
        .slogan span:last-child{
          margin-top: 40px;
          margin-bottom: 5px;
        }  
      }

      @media(max-height: 730px) {
        .contacts-container {
          .btn-find {
            /* display:none; */
          /*  margin-top: 20px;*/
          }
        }
      }
      @media(max-height: 900px) {
        .contacts-container {
          .btn-find {
            /* display:none; */
          /*  margin-top: 20px;*/
          }
        }
      }
    }
  }
}

@import "mixins";
@import "fonts";
@import "reset";

$images_dir: '/images/';

$color_blue: #038ed3;
$color_red: #e43026;

@import "common";
@import "popups";

@import "blocks/header";
@import "blocks/nav";
@import "blocks/prices";
@import "blocks/services";
@import "blocks/about";
@import "blocks/usefull";
@import "blocks/paket";
@import "blocks/offices";
@import "blocks/reviews";
@import "blocks/map";
@import "blocks/footer";
@import "blocks/article";

body > nav {

  $page_images_dir: $images_dir + 'nav/';

  margin: 46px 0 46px 0;

  .container {
    height: 100%;
    width: 100%;
    max-width: 1200px;

  }

  ul{
    overflow: hidden;
    font-size: 0;
    text-align: center;
    font-size: 18px;

  }

  li{
    // margin-left: 50px;
    display: inline-block;
    white-space: nowrap;
    margin-bottom: 20px;
    @media (max-width: 1205px) {
      margin-left: 25px;
    }

    @media (max-width: 1000px) {
      width: 50%;
      margin-left: 0;
    }

    @media (max-width: 600px) {
      width: 100%;
      margin-left: 0px;
      text-align: left;
    }

    @media (max-width: 330px) {
      margin-left: 10px;
    }
  }

  a{
    color: $color_blue;
    font-size: 20px;
    padding-left: 38px;
    line-height: 32px;
    display: inline-block;

    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }

    @include background-image-retina($page_images_dir + 'warning', 'jpg', 32px, 32px);
    &.question{
      @include background-image-retina($page_images_dir + 'question', 'jpg', 32px, 32px);
    }

    @media (max-width: 1090px) {
      font-size: 18px;
    }
  }
}

/*
html.popup-opened {
  height: 100%;
  overflow: hidden;

  body {
    overflow: hidden;
    height: 100%;
  }
}
*/

html, body {
  min-height: 100%;
  position: relative;
}

#popup_overlay {
  position: fixed;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 10000;
  display: none;
}

.popup {
  z-index: 10001;
  position: absolute;
  left: 50%;
  overflow: hidden;
  margin-bottom: 50px;
  width: 795px;
  margin-left: -398px;
  top: 50px;
  display: block;
  padding-right: 75px;
  cursor: default;

  .btn-close {
    right: 0;
    top: 0;
    display: block;
    @include background-image-retina($images_dir + 'popup_close', 'png', 54px, 70px);
    padding-top: 60px;
    width: 54px;
    height: 70px;
    position: absolute;
  }

  .popup-inner {
    padding: 25px 0 60px;
    @include border-radius(5px);
    background: #fff;
  }

  @media (max-width: 800px) {
    width: 600px;
    margin-left: -300px;
  }

  @media (max-width: 620px) {
    width: 470px;
    margin-left: -235px;
  }

  @media (max-width: 500px) {
    padding-right: 0;
    width: 400px;
    margin-left: -200px;
  }

  @media (max-width: 420px) {
    left: 3px;
    right: 3px;
    width: auto;
    margin-left: 0;
  }
}

.popup-request {
  text-align: center;
  .more {
    margin-top: 20px;
    display: none;
    @media (max-width: 500px) {
      display: inline-block;
    }
  }
  .form {
    text-align: left;
    width: 360px;
    margin: 45px auto 0;
    max-width: 95%;

    .input {
      border: 2px solid #e2e2e2;
      width: 100%;
      margin-bottom: 18px;
      @include border-radius(6px);
      outline: none;

      @include scada;
      font-size: 18px;

      &::-webkit-input-placeholder {
        color: #c0c0c0;
      }
      &::-moz-placeholder {
        color: #c0c0c0;
      }
      &:-moz-placeholder {
        color: #c0c0c0;
      }
      &:-ms-input-placeholder {
        color: #c0c0c0;
      }
    }

    .input{
      height: 46px;
      line-height: 42px;
      padding: 0 18px;
    }

    .btn {
      display: block;
      text-align: center;
      width: 100%;
    }

    textarea {
      padding: 10px;
      height: 136px;
      resize: none;
    }

    label {
      display: block;
      font-size: 18px;
      line-height: 100%;
      margin-bottom: 10px;
    }

    .phone-container {
      margin: 18px -10px 0 0;
      overflow: hidden;
      input {
        float: left;
        width: 60px;
        text-align: center;
        margin-right: 10px;
        display: block;
        padding: 0 2px;
        &:last-child {
          float: none;
          margin-left: 140px;
          padding: 0 8px;
          width: 220px;
          text-align: left;

          @media (max-width: 420px) {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }
}

article {
  margin: 50px 0;
  h2 {
    margin-bottom: 50px;
    margin-top: 0;
  }
  .hotel-img{
    text-align: center;
    img {
      display: inline-block !important;
    }
  }
  .gray {
    background: #f5f5f5;
    padding: 30px 20px;
    height: 100%;

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }



  section {
    margin-bottom: 35px;

    h3, h4 {
      text-align: center;
      display: block;
      margin-bottom: 25px;
    }

    p {
      margin-left: 20px;
    }

    &.with-image {
      h3 {
        text-align: left;
        margin-bottom: 0;
      }
      p {
        margin-left: 0;
      }
    }
  }

  .container {
    height: 100%;
    width: 100%;
    max-width: 1200px;
  }
.hotel-title{
  h1{
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: red;
  }
}
.hotel-subtitle{
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  color: #000;
}
.hotel-items{
  font-size: 20px;
  text-align: center;
  font-weight: normal;
  color: #000;
}
.hotel-items-price{
  font-size: 20px;
  text-align: left;
  font-weight: normal;
  color: #000;
}
.hotel-menu{
  font-size: 20px;
  text-align: center;
  font-weight: normal;
  font-style: italic;
  color: #000;
}
.hotel-items-name{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  color: #000;
}
.hotel-desc{
  font-size: 20px;
  text-align: center;
  color: #000;
}
.hotel-price{
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  color: #000;
}
.tour-title {
  h1{
  font-size: 30px;
  text-align: right;
  font-weight: bold;
  color: #000;
  margin-right: 32px;
  }
}
.tour-nights {
  font-size: 20px;
  text-align: right;
  font-weight: bold;
  color: #000;
  margin-right: 32px;
}
.table-box {
  table, tr, td {
    border: 1px solid #000;
    font-style: italic;
    max-width: 100%;
    padding: 5px;
  }
}
.table-box_prices {
  table, tr, td, th {
    border: 1px solid #000;
    font-style: italic;
    max-width: 100%;
    font-size: 14px;
  }
  th {
    padding: 10px;
  }
}
  b, strong {
    @include bold;
  }

  figure {
    float: left;
    margin: 0 40px 40px 0;
  }

  h3, h4, h5, h6 {
    color: #2a9fda;
    font-size: 30px;
    line-height: 100%;
    display: block;
  }


  p, li{
    font-size: 16px;
    line-height: 19px;
  }

  p {
    margin-top: 15px;
    display: block;
    margin-right: 32px;

    a {
      color: #2a9fd9;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    &.with-warning, &.with-check {
      padding-left: 43px;
      background-repeat: no-repeat;
      background-position: left center;
      padding-top: 4px;
      min-height: 33px;
    }

    &.with-warning {
      @include background-image-retina($images_dir + 'line_warning', 'png', 33px, 33px);
    }

    &.with-check {
      @include background-image-retina($images_dir + 'line_check', 'png', 33px, 33px);
    }
  }

  @media (max-width: 767px) {
    h2 {
      margin-bottom: 30px;
    }
    figure {
      float: none;
      text-align: center;
      margin: 0 0 20px 0;

      img {
        max-width: 100%;
      }
    }

    p {
      margin-right: 0;
    }
    .info-block {
      height: auto;
      h2 {
        font-size: 25px;
      }
      p {
        text-align: center;
        font-size: 20px;
      }
    }

  }
}

.info-block {
    height: 480px;
    margin: 15px 0;
    position: relative;
    border-radius: 5px;
    background-color: #f9f9f9;
    overflow: hidden;

    img {
      height: 280px;
      display: block;
      object-position: center;
      object-fit: cover;
      width: 100%;
    }

    h2 {
      margin: 15px auto;
      font-size: 24px;
    }
    p {
      text-align: center;
      font-size: 25px;
    }

    .info-block-btn {
      display: block;
      text-align: center;
      width: 240px;
      padding: 15px 0;
      position: absolute;
      background-color: transparent;
      border: 2px solid #e43026;
      border-radius: 5px;
      color: #000;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 20px;
      &:hover {
        color: #fff;
        background-color: #e43026;
      }
    }
}

@media (max-width: 1199px) {
  .info-block {
    h2 {
      font-size: 22px;
    }
    p {
      text-align: center;
      font-size: 20px;
    }
  }

}

@media (max-width: 767px) {
  .info-block {
    height: auto;
    h2 {
      font-size: 25px;
    }
    p {
      text-align: center;
      font-size: 20px;
    }
  }

}

.hotel-image {
  object-fit: cover;
  width: 100%;
  height: 250px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
.hotel-image {
  height: auto;
}
}

.section-prices {
  $page_images_dir: $images_dir + 'prices/';


  h2 {
    margin-bottom: 75px;
  }

  > p {
    font-size: 20px;
    display: block;
    text-align: center;
    line-height: 100%;
    margin-bottom: 40px;
    &:first-child {
      margin-top: -20px;
    }
  }

  .price-item {
    padding: 28px 16px 46px;
    background: #f5f5f5;
    @include border-radius(7px);
    width: 280px;
    height: 550px;
    text-align: center;
    color: #737373;
    border: 4px solid #f5f5f5;
    margin: 0 auto 25px;
    max-width: 100%;
    position: relative;

    @media (max-width: 500px) {
      height: auto;
    }

    &:hover {
      background: #fff;
      .top-container {
        border-color: #f5f5f5;
      }
    }

    .badges {
      right: -4px;
      top: 100px;
      position: absolute;
      @media (max-width: 450px) {
        top: 10px;
      }

      @media (max-width: 350px) {
        top: -23px;
      }

      > span {
        height: 23px;
        line-height: 23px;
        padding: 0 3px 0 7px;
        display: block;
        font-size: 16px;
        color: #fff;
        position: relative;
        margin-bottom: 7px;

        &:after {
          content: '';
          display: block;
          width: 7px;
          height: 29px;
          position: absolute;
          right: -7px;
          top: 0;
        }

        &.badge-blue {
          background: #0b93ee;
          &:after {
            @include background-image-retina($page_images_dir + 'badge_blue', 'png', 7px, 29px);
          }
        }

        &.badge-red {
          background: #e53c32;
          &:after {
            @include background-image-retina($page_images_dir + 'badge_red', 'png', 7px, 29px);
          }
        }
      }
    }

    .top-container {
      padding-bottom: 14px;
      border-bottom: 4px solid #fff;
      margin-bottom: 33px;
    }

    .type {
      display: block;
      font-size: 20px;
      line-height: 100%;
      margin-bottom: 18px;
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
      b {
        display: block;
        font-size: 30px;
        line-height: 100%;
        color: #000000;
        @include scada;
        text-transform: uppercase;
      }
    }

    .top-container > .price {
      margin-top: 27px;
      @include bold;
      color: $color_red;
      font-size: 60px;
      position: relative;
      line-height: 100%;
      display: inline-block;

      i {
        font-style: normal;
        position: absolute;
        right: -8px;
        top: -30px;
        font-size: 16px;
        color: #737373;
      }
    }

    p {
      height: 143px;
      font-size: 16px;
      line-height: 19px;
      margin-left: 27px;
      text-align: left;
      display: block;

      @media (max-width: 500px) {
        height: auto;
        margin-bottom: 20px;
      }
    }

    .btn {
      margin-top: 28px;
      width: 190px;
      display: inline-block;
    }

    &--full {
      width: auto;
      padding: 14px 18px;
      margin-bottom: 40px;
      height: auto;
      &:hover {
        background: #f5f5f5;
      }

      color: #000;
      p {

        @media (max-width: 760px) {
          margin-left: 0;
        }

        margin-bottom: 20px;
        &.info {
          background-position: 7px 20px;
          @include italic;
          color: #545454;
          font-size: 14px;
          border-top: 3px solid #fff;
          padding-top: 20px;
          margin-top: 15px;
          margin-bottom: 0;
        }

        &.disclaimer {
          @include italic;
          color: #545454;
          font-size: 14px;
          margin-top: 40px;
        }
      }

      ul {
        margin: 25px;
        li {
          margin-top: 7px;
          margin-bottom: 7px;
        }
      }
      h3 {
        color: #2a9fd8;
        font-size: 30px;
        b {
          color: #2a9fd8;
          display: inline;
        }
      }

      .top-container {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 15px;

        > .price {
          margin-top: 15px;
        }
      }

    }

    &--text {
      background: #fff;
      border-color: #fff;
      &:hover {
        background: #fff;
      }

      h3 {
        text-align: center;
        margin-bottom: 15px;
        line-height: 32px;
      }

      .top-container {
        padding-bottom: 15px;
        margin-bottom: 30px;
        background: url($images_dir + 'subheader_dashed.png') repeat-x bottom;
      }
    }
  }

  .btn-big {
    width: 576px;
    max-width: 98%;
    margin: 70px auto;
    display: block;

    @media (max-width: 450px) {
      margin-top: 50px;
    }
    @media (max-width: 380px) {
      margin-top: 30px;
    }
  }
}

.price-additional{
  p, ul{
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
  }
  ul{
    padding-left: 20px;
  }
}
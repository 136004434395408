.section-map {
  $page_images_dir: $images_dir + 'map/';

  position: relative;
  border-top: 2px solid #cecece;
  background: #eee;

  .map {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  h2 {
    margin-bottom: 30px;
  }

  .content-wrapper {
    width: 600px;
    max-width: 100%;
    padding: 40px 10px 40px;
    position: relative;
    z-index: 1;
    height: auto;
  }

  .content-container {
    max-width: 100%;
    background: #fff;
    @include border-radius(10px);
    padding: 32px 20px 0;

    .more {
      margin-top: 43px;
      @include background-image-retina($page_images_dir + 'link', 'jpg', 48px, 40px);
      background-position: left 3px;
      padding-left: 55px;
      margin-left: 67px;
      font-size: 20px;
      line-height: 24px;
      display: inline-block;
      @include scada;
    }

    small{
      display: block;
      font-size: 18px;
    }

    .btn {
      margin: 0 auto;
      width: 230px;
      display: block;
    }
  }

  .bottom-container {
    border-top: 4px solid #f5f5f5;
    padding-top: 40px;
    padding-bottom: 50px;

    > span {
      display: block;
      text-align: center;
      @include bold;
      font-size: 20px;
      color: #727272;
      margin-bottom: 8px;
    }
  }

  .contacts {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a, address {
      margin-left: 47px;
      padding-left: 52px;
      font-size: 20px;
      line-height: 100%;
      margin-bottom: 20px;
      color: #000;
      display: inline-block;
      background: no-repeat left top;

      @media(max-width: 400px) {
        font-size: 16px;
      }
    }

    .link {
      @include background-image-retina($page_images_dir + 'link', 'jpg', 32px, 33px);
      background-position: left center;
      text-decoration: underline;
      color: #018cd2;
      line-height: 22px;
      &:hover {
        text-decoration: none;
      }
    }
    .phone_1 {
      @include background-image-retina($page_images_dir + 'vodafone', 'png', 33px, 32px);
      line-height: 32px;
      background-position: left center;
      padding-left: 50px;
    }
    .phone_2 {
      @include background-image-retina($page_images_dir + 'kievstar', 'png', 33px, 32px);
      line-height: 32px;
      background-position: left center;
      padding-left: 50px;
    }
    .phone_3 {
      @include background-image-retina($page_images_dir + 'lifecell', 'png', 33px, 32px);
      line-height: 32px;
      background-position: left center;
      padding-left: 50px;
    }
    .fax {
      @include background-image-retina($page_images_dir + 'fax', 'jpg', 32px, 32px);
      line-height: 32px;
    }
    .mail {
      @include background-image-retina($page_images_dir + 'mail', 'jpg', 33px, 21px);
      line-height: 21px;
    }
    address {
      @include background-image-retina($page_images_dir + 'address', 'jpg', 33px, 35px);
      background-position: left 5px;
      @media(max-width: 400px) {
        background-position: left top;
      }
    }
  }

  @media (max-width: 400px) {
    .content-container {
      .more {
        margin-left: 20px;
        font-size: 17px;
      }
    }
    .contacts {
      a, address {
        margin-left: 20px;
      }
    }
  }

}

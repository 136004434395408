.section-reviews {
  $page_images_dir: $images_dir + 'reviews/';

  padding: 35px 0 45px;
  h2 {
    margin-bottom: 60px;
  }

  .row {
    padding-left: 20px;
    margin-bottom: 30px;
  }

  .review-man {
    width: 80px;
    float: left;
    img {
      max-width: 80px;
      display: block;
    }
  }

  .row-review {
    .review-content {
      &:after {
        content: '';
        border: 13px solid transparent;
        height: 0;
        width: 0;
        float: left;
        line-height: 0;
        font-size: 0;
        left: -24px;
        top: 26px;
        border-right-color: #fff;
        position: absolute;
        z-index: 111;

        @media (max-width: 430px) {
          left: 50%;
          margin-left: -13px;
          top: -26px;
          border-bottom-color: #fff;
          position: absolute;
          z-index: 111;
          border-right-color: transparent;
        }
      }
    }
  }

  .review-content {
    padding: 23px;
    background: #fff;
    @include border-radius(10px);
    margin-left: 100px;
    position: relative;

    span {
      font-size: 12px;
      color: #727272;
      line-height: 100%;
      display: block;
      margin-bottom: 15px;
      margin-left: 3px;
    }

    p {
      font-size: 16px;
      line-height: 19px;
      padding-right: 67px;
    }

    .actions {
      margin-top: 20px;
      color: #0b92ed;
      a {
        color: #0b92ed;
      }
    }
  }

  @media (max-width: 430px) {
    .review-man {
      width: 100%;
      float: none;
      margin-bottom: 20px;
      img {
        margin: 0 auto;
      }
    }

    .review-content {
      margin-left: 0;
      p {
        padding-right: 0;
      }
    }
  }

  .row-form {

    .review-man {
      background: url($page_images_dir + 'write_icon.png') no-repeat top center;
      width: 80px;
      height: 80px;
    }
    .review-content {
      border: 2px solid #1e9bef;
      padding: 16px 23px;
      margin-top: 8px;

      span {
        font-size: 20px;
        color: #727272;
        margin: 0;
      }

      &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 28px;
        @include background-image-retina($page_images_dir + 'arrow_left', 'png', 16px, 28px);
        left: -15px;
        top: 50%;
        margin-top: -14px;
      }
    }

    @media (max-width: 430px) {
      .review-man {
        width: 100%;
      }
      .review-content {
        &:after {
          @include background-image-retina($page_images_dir + 'arrow_top', 'png', 28px, 16px);
          width: 28px;
          height: 16px;
          top: -16px;
          left: 50%;
          margin-top: 0;
          margin-left: -14px;
        }
      }
    }
  }
}
li.first-2 {
  text-align: justify;
  list-style-type: none;
  padding-left: 1.5625em;
  padding-bottom: 0.3125em;
  background-repeat: no-repeat;
  background-image: url("/images/paket/blue.png");
  list-style: none !important;
  margin: 0.3125em 0 0.3125em 2.5em;
  margin-right: 32px;
}
li.second-2 {
  text-align: justify;
  margin: 0.3125em 0 0.3125em 2.5em;
  padding-bottom: 0.1875em;
  list-style-type: none;
  padding-left: 1em;
  background-repeat: no-repeat;
  background-image: url("/images/paket/bullet.png");
  list-style: none !important;
  background-position-y: center;
}
#paket {
  background-color: white;
  $page_images_dir: $images_dir + "paket/";
  width: 90%;
  margin: 0 auto;
  font-size: 100%/1.5em;
  display: table;
  height: auto;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    padding-right: 2%;
  }
  .all {
    width: 100%;
  }

  .border {
    width: 70%;
    margin: 3em auto 3em auto;
    @media (max-width: 991px) {
      width: 100%;
      padding-right: 20px;
    }
  }
  .info1,
  .info2 {
    width: 100%;
    @media (min-width: 992px) {
    }
  }
  .color_style {
    background-color: #f5f5f5;
    padding: 0 1.25em 0.5em 1.875em;
    width: 100%;
    float: left;
    position: relative;
    margin: 2em auto 0.5em auto;
    @media (min-width: 992px) {
      margin-top: 2em;
      margin-bottom: 0.5em;
      margin-right: 1em;
      width: 48%;
      float: left;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      width: 100%;
      padding: 0 0.3em 0.5em 0.1em;
      margin-top: 0.3em;
    }
    @media (max-width: 991px) {
      height: inherit !important;
    }
  }
  .color style ul li {
    color: #2f9ae0;
    font-size: 1em;
    padding-left: 1.25em;
  }

  .blok_ceni {
    display: table;
    width: 100%;
    height: 3.75em;
    position: relative;
    @media (min-width: 320px) and (max-width: 440px) {
      width: 109%;
    }
    @media (min-width: 441px) and (max-width: 525px) {
      width: 110%;
      margin-bottom: 2em;
    }
    @media (min-width: 526px) and (max-width: 600px) {
      width: 135%;
      margin-bottom: 2em;
    }
    @media (min-width: 601px) and (max-width: 767px) {
      width: 112%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      width: 112%;
    }
    @media (min-width: 992px) {
      width: 118%;
    }
    @media (min-width: 1200px) {
      width: 138%;
    }
  }
  .cena {
    display: table-cell;
    width: 70%;
    margin: 0 auto;
    padding-left: 11em;
    float: left;
    @media (max-width: 388px) {
      margin: auto;
      padding-left: 28%;
      float: left;
      width: 50%;
    }
    @media (min-width: 389px) and (max-width: 440px) {
      margin: auto;
      padding-left: 33%;
      float: left;
      width: 48%;
    }
    @media (min-width: 441px) and (max-width: 480px) {
      margin: auto;
      padding-left: 38%;
      float: left;
      width: 50%;
    }
    @media (min-width: 481px) and (max-width: 487px) {
      margin: auto;
      padding-left: 34%;
      float: left;
      width: 49%;
    }
    @media (min-width: 488px) and (max-width: 525px) {
      margin: auto;
      padding-left: 35%;
      float: left;
      width: 50%;
    }
    @media (min-width: 526px) and (max-width: 600px) {
      margin: auto;
      padding-left: 28%;
      float: left;
      width: 49%;
    }
    @media (min-width: 601px) and (max-width: 767px) {
      margin: auto;
      padding-left: 38%;
      float: left;
      width: 50%;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      margin: auto;
      padding-left: 34%;
      float: left;
      width: 50%;
    }

    @media (min-width: 1200px) {
      margin: auto;
      padding-left: 30%;
      float: left;
      width: 50%;
    }
  }
  .cena_long {
    display: table-cell;
    width: 70%;
    margin: 0 auto;
    padding-left: 11em;
    float: left;
    @media (max-width: 388px) {
      margin: auto;
      padding-left: 0%;
      float: left;
      width: 50%;
    }
    @media (min-width: 389px) and (max-width: 440px) {
      margin: auto;
      padding-left: 0%;
      float: left;
      width: 48%;
    }
    @media (min-width: 441px) and (max-width: 480px) {
      margin: auto;
      padding-left: 0%;
      float: left;
      width: 50%;
    }
    @media (min-width: 481px) and (max-width: 487px) {
      margin: auto;
      padding-left: 0%;
      float: left;
      width: 49%;
    }
    @media (min-width: 488px) and (max-width: 525px) {
      margin: auto;
      padding-left: 0%;
      float: left;
      width: 50%;
    }
    @media (min-width: 526px) and (max-width: 600px) {
      margin: auto;
      padding-left: 20%;
      float: left;
      width: 49%;
    }
    @media (min-width: 601px) and (max-width: 767px) {
      margin: auto;
      padding-left: 20%;
      float: left;
      width: 50%;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      margin: auto;
      padding-left: 0%;
      float: left;
      width: 50%;
    }

    @media (min-width: 1200px) {
      margin: auto;
      padding-left: 20%;
      float: left;
      width: 50%;
    }
  }
  .blok_ceni1 {
    display: table;
    width: 100%;
    height: 3.75em;
    position: relative;
  }

  .cena1 {
    display: table-cell;
    width: 100%;
    margin: 0 auto;
  }

  h2 {
    text-align: center;
    margin-bottom: 0px;
    @media (max-width: 440px) {
      margin: 0 auto 0 auto;
    }
  }
  h2.hey {
    text-align: center;
    font-size: 1.875em;
    color: #2f9ae0;
  }
  h2.cena {
    text-align: center;
    font-size: 2.5em;
    color: #ff0000;
    margin-top: 0.3125em;
  }

  p {
    font-size: 1.25em;
    /*padding-left: 38px;
            line-height: 32px;
            display: inline-block;*/
    text-align: center;
  }
  p.style {
    font-size: 1em;
    text-align: center;
  }
  p.paket {
    padding-top: 0.9375em;
    margin: 0.3em auto 0.3em auto;
    @media (max-width: 600px) {
      padding-top: 0;
      margin: 0 auto 0 auto;
    }
  }
  p.price {
    font-size: 2.5em;
    text-align: center;
    font-weight: bold;
    line-height: 100%;
  }
  p.price1 {
    font-size: 2.5em;
    text-align: center;
    font-weight: bold;
    line-height: 100%;
    padding-left: 8%;
    color: $color_red;
  }
  p.pack {
    font-size: 1em;
    text-align: center;
    text-decoration-color: red;
  }
  span.red {
    color: red;
  }
  h3.ref {
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    font-size: 1em;
    padding-top: 0.1875em;
  }
  ul {
    overflow: hidden;
    text-align: center;
    line-height: 100%;
    padding-top: 0.625em;
    display: table-row;
  }
  ul.height {
    overflow: hidden;
    text-align: center;
    line-height: 100%;
    padding-top: 0.625em;
    display: table-row;
  }

  li.first {
    text-align: justify;
    list-style-type: none;
    padding-left: 1.5625em;
    padding-bottom: 0.3125em;
    @include background-image-retina(
      $page_images_dir + "blue",
      "png",
      1.25em,
      1.25em
    );
  }
  li.second {
    text-align: justify;
    margin: 0.3125em 0 0.3125em 2.5em;
    padding-bottom: 0.1875em;
    list-style-type: none;
    padding-left: 1em;
    @include background-image-retina(
      $page_images_dir + "bullet",
      "png",
      12px,
      14px
    );
    @media (max-width: 440px) {
      margin: 0.1em 0 0.1em 0.8em;
    }
  }
  li.comment1 {
    text-align: justify;
    margin-left: 1em;
    padding-bottom: 0.1875em;
    list-style-type: none;
    padding-left: 1em;
    font-style: italic;
    text-align: justify;
    color: grey;
    &:before {
      content: "-";
    }
  }

  li.red {
    font-weight: bold;
    text-align: justify;
    list-style-type: none;
    padding-left: 25px;
    padding-bottom: 0.3125em;
    @include background-image-retina(
      $page_images_dir + "red",
      "png",
      1.25em,
      1.25em
    );
  }
  li.double_red {
    color: red;
    font-weight: bold;
    text-align: justify;
    list-style-type: none;
    padding-left: 25px;
    padding-bottom: 0.3125em;
    @include background-image-retina(
      $page_images_dir + "red",
      "png",
      1.25em,
      1.25em
    );
  }
  li.comment {
    margin: 1.25em auto 0 auto;
    font-style: italic;
    text-align: justify;
    list-style-type: none;
    padding-left: 1.5625em;
    padding-bottom: 0.3125em;
    color: grey;
  }
  li.info {
    border-top: 0.25em solid white;
    margin: 0.625em auto 0.625em auto;
    font-style: italic;
    text-align: justify;
    list-style-type: none;
    padding-left: 1.5625em;
    padding-bottom: 0.125em;
    /*padding-top: 0.3125em;*/
    color: grey;
    @include background-image-retina(
      $page_images_dir + "info",
      "png",
      1.25em,
      1.25em
    );
  }

  #pic_ref {
    display: table-cell;
    content: url("/images/paket/refundable.png");
    background-repeat: no-repeat;
    width: auto;
    height: auto;
    margin: 0;
    padding: 8px 5px 0 38px;
    top: -3.125em;
    @media (min-width: 320px) and (max-width: 388px) {
      /*padding-left: 1em;*/
      width: 50%;
      height: 100%;
      padding-right: 3.5%;
    }
    @media (min-width: 389px) and (max-width: 440px) {
      padding-left: 14%;
    }
    @media (min-width: 441px) and (max-width: 525px) {
      padding-left: 16%;
    }
    @media (min-width: 526px) and (max-width: 574px) {
      padding-left: 6%;
    }
    @media (min-width: 575px) and (max-width: 600px) {
      padding-left: 8%;
    }
    @media (min-width: 601px) and (max-width: 640px) {
      padding-left: 20%;
    }
    @media (min-width: 641px) and (max-width: 666px) {
      padding-left: 21%;
    }
    @media (min-width: 667px) and (max-width: 700px) {
      padding-left: 22%;
    }
    @media (min-width: 701px) and (max-width: 727px) {
      padding-left: 9.1em;
    }
    @media (min-width: 728px) and (max-width: 767px) {
      padding-left: 9.8em;
    }
  }

  #pic_non {
    content: url("/images/paket/non_refundable.png");
    background-repeat: no-repeat;
    width: auto;
    height: auto;
    margin: 0;
    padding-top: 23px;
    top: -3.125em;
    padding-left: 0.5em;
    @media (min-width: 320px) and (max-width: 388px) {
      /*padding-left: 1em;*/
      width: 50%;
      height: 85%;
      padding-right: 3.5%;
    }
    @media (min-width: 389px) and (max-width: 440px) {
      padding-left: 6.5%;
    }
    @media (min-width: 441px) and (max-width: 525px) {
      padding-left: 8.5%;
    }
    @media (min-width: 526px) and (max-width: 574px) {
      padding-left: 0;
    }
    @media (min-width: 575px) and (max-width: 600px) {
      padding-left: 3%;
    }
    @media (min-width: 601px) and (max-width: 640px) {
      padding-left: 14%;
    }
    @media (min-width: 641px) and (max-width: 666px) {
      padding-left: 16%;
    }
    @media (min-width: 667px) and (max-width: 700px) {
      padding-left: 17%;
    }
    @media (min-width: 701px) and (max-width: 727px) {
      padding-left: 18%;
    }
    @media (min-width: 728px) and (max-width: 767px) {
      padding-left: 19%;
    }
  }

  #fee {
    content: url("/images/paket/fee.png");
    background-repeat: no-repeat;
    width: auto;
    height: auto;
    margin: 0;
    padding: 20px 0 0 38px;
    top: -3.125em;
    @media (min-width: 320px) and (max-width: 388px) {
      /*padding-left: 1em;*/
      width: 50%;
      height: 85%;
      padding-right: 3.5%;
    }
    @media (min-width: 389px) and (max-width: 440px) {
      padding-left: 14%;
    }
    @media (min-width: 441px) and (max-width: 525px) {
      padding-left: 15%;
    }
    @media (min-width: 526px) and (max-width: 574px) {
      padding-left: 6%;
    }
    @media (min-width: 575px) and (max-width: 600px) {
      padding-left: 8%;
    }
    @media (min-width: 601px) and (max-width: 640px) {
      padding-left: 20%;
    }
    @media (min-width: 641px) and (max-width: 666px) {
      padding-left: 21%;
    }
    @media (min-width: 667px) and (max-width: 700px) {
      padding-left: 22%;
    }
    @media (min-width: 701px) and (max-width: 727px) {
      padding-left: 9.1em;
    }
    @media (min-width: 728px) and (max-width: 767px) {
      padding-left: 9.8em;
    }
  }

  sup {
    color: grey;
    top: -0.8em;
    font-size: 50%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
}
